//importing settings
@import "../skeleton/settings";

//---------------[homepage]-----------------//
#home {
  @extend .page;
}

//Hero Carousel
.home_carousel {
  @extend .section;
}


.home_platforms {
  margin-top: $spacing5*2;
}