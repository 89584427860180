//import settings
@import "../skeleton/settings";

//---------------release item info page-----------------------//

#\[item\] {
  & > .page_container {
    @extend .container_col--center;
    margin: $pageOffsetTop auto $spacing5;
  }
}

.album_platforms {
  @extend .container;
  margin-top: $spacing4;
}

.album_disp {
  @extend .container_row--center;
  margin: $spacing5 auto $spacing1;
}

.album_cover {
  box-shadow: $dropShadowBlack;
}

.album_info {
  max-width: $pageWSmall;
  margin-top: 0;
  padding: $spacing1;
}

.album_description {
  margin-top: $spacing3;
}

.albumInfo-item {
  margin: 0 0.5rem 2rem;
  font-size: 0.75rem;
}

.album_spotify {
  height: 400px;
  & > iframe {
    height: 100%;
  }
}

.album_media {
  margin-top: $spacing5;
  padding: $spacing1;
}

//---------------[Media Queries for different screen sizes]-----------------//

@media only screen {
  //for screens bigger then 632px
  @media (min-width: 632px) {
    .album_spotify {
      height: unset;
    }
  }
}