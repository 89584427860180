//------------[Breakpoints]-------------//
@import "mixins/mixins";

//grid
$bpSmall: 32.5em;
$bpMedium: 56.25em;
$bpMLarge: 90em;
$bpLarge: 130em;
$bpXLarge: 160em;



//----------------[colors]----------------//

//colorTheme
$color1: #e9ba41;
$color2: #efefef;
$color3: #a3a3a3;
$color4: #222222;
$color5: #0e0e0e;
$color6: #000000;


//colorNames
$colorBlack: $color6;
$colorDarkGrey: $color4;
$colorGrey: #a3a3a3;
$buttonColor: $color1;



//background colors
$backgroundPrimC: #0e0e0e;

//global colors
$footerColor: none;
$dropShadowBlackColor: rgba(0,0,0,0.5);

//Navigation color
$navSolidColor: #000000;

//font colors
$fontColor1: $color2;
$fontColor2: $color3;
$fontDColor1: $color5;

//platform colors
$platformIconColor-inactive: $fontColor1;
$platformIconColor-spotify: #1ed760;
$platformIconColor-youtube: #ff1d1d;


//----------------[fonts]----------------//
$primaryFont: 'Bellota Text', sans-serif;



//----------------[Sizes]----------------//

//scaling
$scale1: scale(1.01);
$scale2: scale(1.1);

//border
$borderRadius: 0;
$borderSize: 2px;
$borderSizeHover: 3px;

//buttons
$buttonWidth: 200px;//fancy buttons
$buttonHeight: 55px;//fancy buttons

//icon sizes
$iconSize1: 30px;
$iconSize2: 40px;

//Navitems
$navLinkSize: 30px;//nav items size

//Navlogo
$navLogoSize: $iconSize1;//nav logo size
$navLogoSizeMax: 70px;//nav logo max size

//pageSizes
$pagePWs: 95vw;//small screen width %
$pagePWm: 90vw;//large screen width %
$pageWSmall: 800px;//small section width
$pageWMedium: 1200px;//medium section width
$pageWLarge: 1600px;//Large section width
$pageWXLarge: 2200px;//X-large section width

//hero
$heroHeight: 60vh;
$heroMaxHeight: 650px;

//content sizes
$contentS: 100vw;

//footer
$footerH: 150px;



//----------[Typography]-----------//
$fontSizeBase: 12px;
$fontSizeModifier: 6px;

$baseFontSize: 1rem;
$fontSize1: 1rem;
$fontSize2: 1.25rem;
$fontSize3: 1.5rem;
$fontSize4: 1.75rem;




//----------[Time]-----------//
$animationSpeed1: 200ms;
$animationSpeed2: 250ms;
$animationSpeed3: 300ms;
$animationSpeed4: 400ms;



//----------[Margins/Paddings]-----------//

//global margins
$gMargin: 1rem;
$spacing1: 0.8rem;
$spacing2: 1.5rem;
$spacing3: 3rem;
$spacing4: 4.5rem;
$spacing5: 6rem;

//Page margin
$pageOffsetTop: $spacing5;//spacing over content on pages

//sections
$sectionPadding: $spacing3 0 $spacing5;//spacing on sections

//Navbar
$navbarPadding: 0.5rem;//navbar padding
$navItemMargins: auto 0.5rem;//margin between items & centration of items vertically

//button
$btnPadding: 0.75rem 2rem;//padding for buttons




//----------------[styling]----------------//

//section separator
$sectionSeparatorStyle: 2px solid rgba($color1, 0.5);


//box styling
$dropShadowBlack: 0 6px 10px $dropShadowBlackColor;
$activeDropShadowBlack: 0 10px 15px $dropShadowBlackColor;

//buttons styling
$btnBorderP: $borderSize solid $fontColor1;
$btnBorderS: $borderSize solid $fontDColor1;


//cover read more
$cover-border-animation-duration: $animationSpeed4;
$cover-border-size: $borderSize;
$cover-border-color: $fontColor1;
$cover-border-width: 90px;
$cover-border-height: 90px;
$cover-border-radius: $borderRadius;