//import settings
@import "src/styles/skeleton/settings";

//---------------[links]-----------------//

//Links
.link {
  display: inline-block;
  color: inherit;
  text-decoration: underline;
  &:hover {
    text-decoration: underline $color1;
  }
  &:visited {
    color: inherit;
  }
  &--hover {
    &:hover {
      @extend .link;
      transform: $scale2;
      transition-duration: $animationSpeed1;
    }
  }
}

.navLink {
  @extend .link--hover;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  &.active {
    border-bottom: 2px solid $color1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}


//platform icons
.spotify {
  &:hover {
    color: $platformIconColor-spotify;
  }
}
.youtube {
  &:hover {
    color: $platformIconColor-youtube;
  }
}