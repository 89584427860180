//import settings
@import "./skeleton/settings";

/*=====================[Global Rules]=====================*/

//hero
.hero {
  position: relative;
  margin: 0;
  height: $heroHeight;
  max-height: $heroMaxHeight;
  &--fade {
    @extend .hero;
    &::after {
      content: "";
      height: 150px;
      width: 100vw;
      position: absolute;
      left: 0;
      bottom: -1.5rem;
      background: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
    }
  }
}

//for element after hero with fade
.fadeIn {
  margin-top: -1px;
}
//end


//Scroll to top Button
.toTop-btn {
  @extend .center;
  background-color: $backgroundPrimC;
  padding: $spacing1/2;
  position: absolute;
  right: $spacing2;
  bottom: $spacing1;
  z-index: 10;
  width: 40px;
  height: 40px;
  transform: translate(0, -100%);
  transition: all ease-in;

  cursor: pointer;
  &--fixed {
    @extend .toTop-btn;
    position: fixed;
    z-index: 10;
    bottom: 90px;
  }
  &:hover {
    transform: translate(0, -100%) scale(1.1);;
  }
}

.toTop-icon {
   width: 100%;
   height: 100%;
 }
//end


//---------------[Media Queries for different screen sizes]-----------------//
@media only screen {
  @media (min-width: $bpSmall) {
    .toTop-btn {
      &--fixed {
        bottom: $spacing2;
      }
    }
  }
}