//import settings
@import "../skeleton/settings";

//---------------[Fixes]-----------------//

*, *::before, *::after {
  box-sizing: border-box;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

//---------------[Base page elements]-----------------//
html {
  height: 100%;
}

body {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  min-height: 100vh;
  overflow: hidden;
}
.App {
  padding-bottom: 80px;
}

.page {
  position: relative;
  //transform: translate3d(0,0,0);
  min-height: 100vh;
  width: 100vw;
  padding-top: 85px;
  &::before {
    content: "";
    display: block;
    filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    z-index: 0;
    background-size: cover !important;
    background-position: top left !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    transform: scale(1.1);
    background: url(../../../public/media/images/backgrounds/background.jpg);
  }
}


.page_container {
  @extend .container_col--center;
  position: relative;
  min-height: 70vh;
  padding-bottom: $spacing4;
}

.page_section {
  width: $pagePWs;
  max-width: $pageWXLarge;
  margin: $spacing3 auto;

  &--fullwidth {
    margin: 0;
    max-width: unset;
    width: 100vw;
  }
}

.section {
  margin: $spacing3 0 $spacing5;
}
.subSection {
  @extend .section;
  margin: $spacing2 0 $spacing4*2;
}

.container {
  position: relative;
  &_row {
    @extend .container;
    @extend .row;
    &--nowrap {
      @extend .container_row;
      @extend .row--nowrap;
    }
    &--center {
      @extend .container_row;
      @extend .center;
    }
  }

  &_col {
    @extend .container;
    @extend .column;
    &--center {
      @extend .container_col;
      @extend .center;
    }
  }
}


//placing object absolute and sizing to 0
.abs0 {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  display: block;
  opacity: 0;
}


@media only screen {
  @media (min-width: $bpSmall) {
    .App {
      padding-bottom: unset;
    }
  }
  @media (min-width: $bpMedium) {
    .page_section {
      width: $pagePWm;
      max-width: $pageWXLarge;
      margin: $spacing3 auto;

      &--fullwidth {
        margin: 0;
        max-width: unset;
        width: 100vw;
      }
    }
  }
}

