//import settings
@import "src/styles/skeleton/settings";

//---------------[Button]-----------------//

//basic button
.btn {
  display: inline-block;
  padding: $btnPadding;
  background: none;
  color: $fontColor1;
  margin: 0 $spacing1;
  cursor: pointer;
  &:hover {
    transform: $scale2;
    transition-duration: $animationSpeed1;
    box-shadow: $dropShadowBlack;
  }
  &:active {
    transform: scale(0.9);
  }
  &--secondary {
    @extend .btn;
    background: none;
    border: $btnBorderS;
    color: $fontDColor1;
  }
}

.row .button {
  margin: $spacing1 $spacing2;
}

//fancy button
.button {
  @extend .btn;
  width: $buttonWidth;
  margin: 50px auto;
  position: relative;
  padding: 0;
  height: $buttonHeight;
  font-size: $fontSize1;
  @include border-radius($borderRadius, 'all');

  &:hover .hoverBtn:before,
  &:hover .hoverBtn:after {
    opacity: 1;
    -webkit-animation: openButton $animationSpeed4;
    /* Chrome, Safari, Opera */
    animation: openButton $animationSpeed4;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    animation-direction: normal;
  }
  &:hover .hoverBtn-bottom:before,
  &:hover .hoverBtn-bottom:after {
    opacity: 1;
    -webkit-animation: openButtonB $animationSpeed4;
    /* Chrome, Safari, Opera */
    animation: openButtonB $animationSpeed4;
    animation-delay: $animationSpeed4;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    animation-direction: normal;
  }

}

.hoverBtn {
  width: 100%;
  height: $buttonHeight;
  position: absolute;
  top: -2px;
  left: 0;

  &:before {
    @extend .abs0;
    border-top: solid $borderSizeHover $buttonColor;
    border-left: solid $borderSizeHover $buttonColor;
    @include border-radius($borderRadius, 'left');
    right: ($buttonWidth/2)-2;
  }
  &:after {
    @extend .abs0;
    border-top: solid $borderSizeHover $buttonColor;
    border-right: solid $borderSizeHover $buttonColor;
    @include border-radius($borderRadius, 'right');
    left: ($buttonWidth/2)-2;
  }
}

.hoverBtn-bottom {
  width: 100%;
  height: $buttonHeight;
  position: absolute;
  left: 0;
  top: 0;


  &:before {
    @extend .abs0;
    height: $buttonHeight;
    border-bottom: solid $borderSizeHover $buttonColor;
    @include border-radius($borderRadius, 'right');
    right: 0;
    bottom: 2px;
  }
  &:after {
    @extend .abs0;
    height: $buttonHeight;
    border-bottom: solid $borderSizeHover $buttonColor;
    @include border-radius($borderRadius, 'left');
    left: 0;
    bottom: 2px;
  }
}


@keyframes openButton {
  0% {
    width: 0;
    height: 0;
  }
  50% {
    width: $buttonWidth/2;
    height: 0;
  }
  100% {
    width: $buttonWidth/2;
    height: $buttonHeight;
  }
}

@keyframes openButtonB {
  0% {
    width: 0;
  }
  100% {
    width: $buttonWidth/2;
  }
}

//end
