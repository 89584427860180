//import settings
@import "src/styles/skeleton/settings";

//---------------[Images]-----------------//

.img {
  display: block;
}

.img--cover {
  @extend .img;
  object-fit: cover;
  object-position: center;
}

img[data-nimg] {
  @extend .img--cover;
}

.backgroundImg {

}
