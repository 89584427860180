//importing settings
@import "../skeleton/settings";

.coverList {
  @extend .container_row--nowrap;
  &--center {
    @extend .coverList;
    @extend .container_row--center;
  }
}

.coverList_row {
  @extend .coverList--center;
  width: 100%;
}

.cover {
  @extend .container;
  margin: 1.5rem 0.75rem 0;
  flex-basis: 300px;
  flex-grow: 1;
  flex-shrink: 1;
  transition-duration: $animationSpeed2;
  box-shadow: $dropShadowBlack;
  &:hover {
    & .cover_image {
      opacity: 50%;
    }
  }
  &--scale {
    @extend .cover;
    &:hover {
      transform: $scale1;
      box-shadow: $activeDropShadowBlack;
    }
  }
  &--empty {
    @extend .cover;
    box-shadow: none;
  }
}

.cover-img {
  @extend .img--cover;
  width: 100%;
  height: 100%;
}

.cover_bottom {
  width: 100%;
}

.cover_info {
  @extend .container_col--center;
  position: absolute;
  //top: 100%;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: $spacing2 $spacing1;
  //transform: translate(0, -100%);
  &:hover .cover_hoverContainer {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
  }
}

.cover_link {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.cover_hoverContainer {
  display: block;
}

.cover_platformsContainer {
  @extend .container_col--center;
  position: absolute;
  right: $spacing1;
  top: $spacing1;
  z-index: 7;
}
.cover-platform {
  margin-bottom: $spacing1;
  max-width: $iconSize1;
  width: 100%;
  height: 100%;
}

.cover_albumInfo {
  text-align: left;
  padding: 0.5rem 0 0 $spacing1;
}

.cover-title {
  &::before {
    content: "- ";
    color: $color1;
  }
}

.cover_readMoreContainer {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 15px black;
  justify-items: center;
  align-items: center;
  padding: 10px;
  font-size: 0.75rem;
  font-weight: bold;
  width: $cover-border-width;
  border: solid 2px #cbd4d9;
  height: $cover-border-height;
  background: unset;
  color: $fontColor1;
  border: unset;
  @include border-radius($cover-border-radius, 'all');
  z-index: 9;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.cover_info {
  &:hover .coverBtn:before,
  &:hover .coverBtn:after {
    opacity: 1;
    -webkit-animation: openCover $cover-border-animation-duration;
    /* Chrome, Safari, Opera */
    animation: openCover $cover-border-animation-duration;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    animation-direction: normal;
  }
  &:hover .coverBtn-bottom:before,
  &:hover .coverBtn-bottom:after {
    opacity: 1;
    -webkit-animation: openCoverB $cover-border-animation-duration;
    /* Chrome, Safari, Opera */
    animation: openCoverB $cover-border-animation-duration;
    animation-delay: $cover-border-animation-duration;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    animation-direction: normal;
  }
}

.cover_readMoreContainer {
  border: 2px solid white;
}

.coverBtn {
  width: 100%;
  height: $cover-border-height;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;

  &:before {
    @extend .abs0;
    border-top: solid $cover-border-size $cover-border-color;
    border-left: solid $cover-border-size $cover-border-color;
    @include border-radius($cover-border-radius, 'left');
    right: ($cover-border-width/2);
  }
  &:after {
    @extend .abs0;
    border-top: solid $cover-border-size $cover-border-color;
    border-right: solid $cover-border-size $cover-border-color;
    @include border-radius($cover-border-radius, 'right');
    left: ($cover-border-width/2);
  }
}

.coverBtn-bottom {
  width: 100%;
  height: $cover-border-height;
  position: absolute;
  left: 0;
  top: 0;


  &:before {
    @extend .abs0;
    height: $cover-border-height;
    border-bottom: solid $cover-border-size $cover-border-color;
    @include border-radius($cover-border-radius, 'right');
    right: 0;
  }
  &:after {
    @extend .abs0;
    height: $cover-border-height;
    border-bottom: solid $cover-border-size $cover-border-color;
    @include border-radius($cover-border-radius, 'left');
    left: 0;
  }
}

@keyframes openCover {
  0% {
    width: 0;
    height: 0;
  }
  50% {
    width: $cover-border-width/2;
    height: 0;
  }
  100% {
    width: $cover-border-width/2;
    height: $cover-border-height;
  }
}

@keyframes openCoverB {
  0% {
    width: 0px;
  }
  100% {
    width: $cover-border-width/2;
  }
}

@media only screen {
  @media (min-width: $bpSmall) {
    .cover_readMoreContainer {
      border: unset;
    }
    .cover_hoverContainer {
      display: none;
    }
  }
}