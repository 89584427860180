//import settings
@import "../skeleton/settings";

//---------------[Navigation]-----------------//

//------------[Navbar]-----------//
.nav {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: $navSolidColor;
  padding: $navbarPadding;
  box-shadow: $activeDropShadowBlack;
  font-weight: 700;

  &.scrolled {
    background: $navSolidColor;
  }
}

.nav_container {
  max-width: $pageWXLarge;
  margin: 0 auto;
  position: relative;
}

.jumpToContent {
  position: absolute;
  z-index: -2;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  font-size: 0;
  transform: translate(-999px, -999px);
}

//containers
.nav_navbar {
  @extend .container_row;
}

.nav_left {
  @extend .row;
  justify-content: left;
  align-content: center;
}

.nav_center {
  @extend .row;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  width: 100%;
}

.nav_right {
  @extend .row;
  justify-content: right;
  text-align: left;
  margin-left: auto;
}


//Logo
.nav_logoHolder {
  @extend .container;
  max-height: $navLogoSizeMax;
  min-height: $navLogoSize;
  max-width: $navLogoSizeMax;
  min-width: $navLogoSize;
}

.nav-logo {
  @extend .img;
  max-width: 100%;
  max-height: 100%;
}

//sign-out button
.nav_signOut {
  cursor: pointer;
}

//-----[navbar elements]-----//

//links & items
.nav_links {
  position: absolute;
  left: 0;
  top: 85px;
  display: none;
  flex-direction: column;
  width: 100%;
  background-color: $navSolidColor;
  text-align: center;
  margin: 0;
  padding-bottom: $spacing3;
  &--show {
    @extend .nav_links;
    display: flex;
  }
}

.nav-item {
  margin: $navItemMargins;
  @extend .link;
  padding: $spacing1 $spacing1/2;
  display: block;
  &.active {
    border: none;
    text-decoration: underline $color1;
  }
}

.nav-icon {
  display: block;
  @extend .nav-item;
}
.nav-findMe {
  height: $navLogoSize;
}
.nav-btn {
  @extend .nav-item;
  margin: $navItemMargins;
}

//-----[mobile bottom bar]-----//
.nav_mobile {
  display: block;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $navSolidColor;
  padding: $spacing1 0;
}

.mobile_navbar {
  @extend .container_row--center;
  justify-content: space-around;
  max-width: 300px;
  margin: 0 auto;
}

.mobile-item {
  @extend .nav-item;
  text-decoration: none;
  &.active {
    text-decoration: none;
    border-bottom: 1px solid $color1;
  }
}

.mobile-icon {
  font-size: 1.5rem;
}

.mobile-label {
  font-size: 0.65rem;
}

//hamburger
.nav_hamburger {
  @extend .center;
  display: none;
  background: none;
  font-size: 30px;
  border: none;
}



@media only screen {
  @media (min-width: $bpSmall) {
    .nav {
      //background: $navTransparentColor;
    }
    .nav_mobile {
      display: none;
    }
    .nav_links {
      z-index: 10;
      display: flex;
      flex-direction: row;
      position: absolute;
      width: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      padding-bottom: unset;
    }
    .nav-item {
      display: inline-block;
      &.active {
        text-decoration: none;
        border-bottom: 2px solid $color1;
      }
    }
    .nav_hamburger {
      display: none;
    }
    .nav_admin {
      position: absolute;
      right: 0;
      top: 86px;
      z-index: 8;
    }
    .nav_signOut {
      background-color: $navSolidColor;
      border: none;
      padding: 10px 20px;
      color: white;
    }
  }
}