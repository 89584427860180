//import settings
@import "../skeleton/settings";

.pageError {
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
  margin: auto;
}