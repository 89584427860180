//import settings
@import "../skeleton/settings";

//---------------[Footer]-----------------//

footer {
  margin-top: auto;
  position: relative;
}

.footer {
  //background: $backgroundPrimC;
  min-height: $footerH;
  padding: $spacing2 $spacing1;
  text-align: center;
  background-color: $footerColor;
}

.footer_left {
  order: 1;

}
.footer_navigation {
  @extend .column;
}

.footer_center {
  @extend .center;
  order: 3
}
.footer_socials {
  @extend .row--center;
  position: relative;
}

.footer_right {
  order: 2
}
.footer_contact {
  @extend .column;
}


.footer-item {
  @extend .navLink;
  margin: $spacing1/2 0;
  display: inline-block;
  &.active {
    border-bottom: none;
    text-decoration: underline $color1;
    &:hover {
      text-decoration: underline $color1;
    }
  }
}

.footer-link {
  @extend .footer-item;
}



@media only screen {
  @media (min-width: $bpSmall) {
    .footer {
      text-align: unset;
    }
    .footer_left {
      text-align: left;
    }
    .footer_right {
      text-align: right;
    }
  }
  @media (min-width: $bpMedium) {
    .footer_left {
      order: 1;
    }
    .footer_center {
      order: 2;
    }
    .footer_right {
      order: 3;
      text-align: right;
    }
  }
}