//import settings
@import "src/styles/skeleton/settings";

//---------------[Typography]-----------------//

//headings sizes
@for $i from 1 through 6 {
  h#{$i}, .h#{$i} {
    font-size: $fontSizeBase + $fontSizeModifier * (6 - $i);
    @extend .center;
    margin: 3rem/($i*0.75) 0rem;
    color: $color1;
    font-family: 'Marcellus', serif;
  }
}

body {
  font-family: $primaryFont;
  line-height: 1.5;
  color: $fontColor1;
}

.paragraph {
  padding: $gMargin 0;
}

@media only screen {
  @media (min-width: $bpSmall) {
    body {
      font-size: $fontSize1;
    }
  }
  @media (min-width: $bpMedium) {
    body {
      font-size: $fontSize1*1.2;
    }
  }
  @media (min-width: $bpLarge) {
    body {
      font-size: $fontSize1*1.3;
    }
  }
}