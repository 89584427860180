//import settings
@import "../skeleton/settings";

//---------------[About section]-----------------//

#about {
  @extend .page;
}

.about_banner {
  max-width: 100%;
  height: 40vh;
  display: none;
}

.about-bannerImg {
  @extend .img--cover;
  width: 100%;
  height: 100%;
}

.about_main {
  @extend .container_row;
  width: 90%;
  margin: 2rem auto 0;
  max-width: 1200px;
}

.about_information {
  text-align: left;
}

.about_PGNLogo {
  @extend .container_col--center;
  width: 100%;
  height: 100%;
  max-width: 400px;
}


//---------------[Contact section]-----------------//

//contact me section
.about_contact {
  @extend .container_col--center;
  padding-top: $spacing4*2;
  border-top: $sectionSeparatorStyle;
}

.contact_cta {
  margin-top: auto;
}


//---------------[Media Queries for different screen sizes]-----------------//
//Media queries for About section
@media only screen {
  @media (min-width: $bpSmall) {
    .about_main {
      width: 75%;
    }
  }
  @media (min-width: $bpMedium) {
    .about_main {
      width: 60%;
    }
  }
}

//media queries for contact section

