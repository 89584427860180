//import settings
@import "src/styles/skeleton/settings";

//---------------[grid]-----------------//

//----Layout----//

.center {
  display: flex;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}

.column {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: stretch;
  min-height: 1px;
}
[class*="col-"]{
  @extend .column;
  margin: 0;
  padding: $spacing1;
}

.col-auto {
  display: flex;
  flex-grow: 1;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &--nowrap {
    @extend .row;
    flex-wrap: wrap;
  }
  &--center {
    @extend .row;
    justify-content: center;
  }
}


//----Grid Generator----//

@mixin makeGrid ($cols-in-grid: 12, $prefix: "col-") {
  @for $i from 1 through $cols-in-grid {
    .#{$prefix}#{$i} {
      width: $i / $cols-in-grid * 100% !important;
    }
  }
}
@media only screen {
  /*for all*/
  @include makeGrid($prefix:"col-");
  /*for mobile*/
  @media (min-width: 0) and (max-width: $bpSmall) {
    @include makeGrid($prefix:"col-s-");
  }
  @media (min-width: $bpSmall) {
    .row--nowrap {
      flex-wrap: nowrap;
    }
  }

  /*for tablet*/
  @media (min-width: $bpSmall) and (max-width: $bpMedium) {
    @include makeGrid($prefix:"col-m-");
  }

  /*for desktop*/
  @media (min-width: $bpMedium) {
    @include makeGrid($prefix:"col-l-");
  }
}