//import settings
@import "../skeleton/settings";


//footer icons
.social_iconContainer {
  position: relative;
  max-width: 60px;
  &:hover {
    display: flex; //wierd bug
  }
}
.social-icon {
  font-size: $iconSize1;
  width: 100%;
  max-height: 100%;
}

@media only screen {
  @media (min-width: $bpLarge) {
    .social_iconContainer {
      max-width: 65px;
    }
  }
  @media (min-width: $bpXLarge) {
    .social_iconContainer {
      max-width: 75px;
    }
  }
}
