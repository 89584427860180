//import settings
@import "../skeleton/settings";


//---------------[Carousel Styling]-----------------//

.carousel {
  @extend .container;
  box-shadow: $dropShadowBlack;
}

.carousel_item {
  position: relative;
  height: 100%;
}

.carousel-img {
  @extend .img--cover;
  height: 100%;
  width: 100%;
}
.carousel_text {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}


//--------hero carousel----------//
.heroCarousel {
  color: #000000;
  height: 100%;
  & .slick-list, .slick-track, .slick-slide > div {
    height: 100%; //carousel fix for images
  }
}

@media only screen {
  @media (min-width: $bpSmall) {

  }
}


//---------------[Slick slider Carousel]-----------------//

.slick-slider {

}

.slick-customArrow {
  @extend .center;
  align-items: center;
  position: absolute;
  display: flex;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0,0,0,0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  transform: translate(0, -50%);
  top: 50%;
  ::before {
    height: 20px !important;
    font-size: 20px;
    color: #fff;
    display: block;
    font-family: revicons sans-serif;
  }
}

.slick-list {
  width: 100%;
}

.slick-customPrev {
  left: 1.5rem !important;
}
.slick-customNext {
  right: 1.5rem !important;
}

.slick-track {
  margin-left: 0;
}

//---------------[React responsive carousel]-----------------//

//component styling
.react-multi-carousel-dot-list {
  position: absolute;
  bottom: $spacing3;
  left: 50%;
  transform: translate(0, -50%);
}

.react-multi-carousel-track {
  height: 100%;
}

.react-multiple-carousel__arrow {
  z-index: 5 !important;
}