//import settings
@import "../skeleton/settings";

//Platform display (find me on)
.platformDisp {

}
.platformDisp_list {
  @extend .container_row;
  @extend .center;
}

//Carousel Item
.platformDisp-item {
  background-size: cover;
  max-height: 400px;
  width: 50%;
  max-width: 300px;
  min-width: 265px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  &:hover {
    & > .platformDisp-name {
      text-decoration: underline $color1;
      transform: $scale2;
      transition-duration: $animationSpeed2;
    }
  }
}

//icon
.platformDisp_iconHolder {
  @extend .center;
  margin: 0 auto;
  max-width: 200px;
  max-height: 200px;
  width: 50%;
  height: 50%;
  &.spotify {
    padding: 15px;
  }
}
.platformDisp-icon {
  height: 100%;
  max-height: 300px;
}