//import settings
@import "../skeleton/settings";

//releases page
#releases {
  @extend .page;
}

//releases list
.releases_main {
  @extend .container_col--center;
}



//----------------Release List--------------------//

.releases_container {
  position: relative;
  align-items: start !important;
}

.releaseCategory {
  @extend .container_col--center;
  width: 100%;
}
.release_list {
  position: relative;
  width: 100%;
  align-items: start !important;
  justify-content: left;
}

.release_label {
  left: 0;
}


//---------------[Media Queries for different screen sizes]-----------------//

@media screen {
  //for screens bigger then smallest (mobile)
  @media (min-width: $bpSmall) {
    .releaseCategory {
     flex-direction: row;
      flex-wrap: nowrap;
      padding: $spacing4 $spacing1;
      border-bottom: 1px solid $color4;
      &:first-child {
        border-top: 1px solid $color4;
      }
    }
    .release_list {
      margin: auto 0;
    }

    .release_label {
      writing-mode:vertical-rl;
      transform: rotate(-180deg);
    }
  }
}
