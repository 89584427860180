@mixin border-radius($cover-border-radius, $sideH) {
  @if $sideH != 'all' {
    -webkit-border-top-#{$sideH}-radius: $cover-border-radius;
    -moz-border-top-#{$sideH}-radius: $cover-border-radius;
    border-top-#{$sideH}-radius: $cover-border-radius;
    -webkit-border-bottom-#{$sideH}-radius: $cover-border-radius;
    -moz-border-bottom-#{$sideH}-radius: $cover-border-radius;
    border-bottom-#{$sideH}-radius: $cover-border-radius;
  } @else if $sideH == 'all' {
    -webkit-border-radius: $cover-border-radius;
    -moz-border-radius: $cover-border-radius;
    border-radius: $cover-border-radius;
  }
}

@mixin abs0 {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  display: block;
  opacity: 0;
}


//------------------Color Theme template-------------------//


$dark-theme: "dark-theme";
$light-theme: "light-theme";

$primary-color-dark: "primary-color-dark";
$primary-color: "primary-color";
$primary-color-light: "primary-color-light";
$primary-color-text: "primary-color-text";
$accent-color: "accent-color";
$primary-text-color: "primary-text-color";
$secondary-text-color: "secondary-text-color";
$divider-color: "divider-color";
$general-background: "general-background";
$general-background-light: "general-background-light";

$themes: (
        $light-theme: (
                $primary-color-dark: #e9ba41,
                $primary-color: #e9ba41,
                $primary-color-light: #DFDFF1,
                $primary-color-text: #FFFFFF,
                $accent-color: #e9ba41,
                $primary-text-color: #0e0e0e,
                $secondary-text-color: #2d2d2d,
                $divider-color: #5b5b5b,
                $general-background: #a3a3a3,
                $general-background-light: #efefef,
          /* ...other color keys with HEX values... */
        ),
        $dark-theme: (
                $primary-color-dark: #e9ba41,
                $primary-color: #e9ba41,
                $primary-color-light: #DFDFF1,
                $primary-color-text: #FFFFFF,
                $accent-color: #e9ba41,
                $primary-text-color: #efefef,
                $secondary-text-color: #a3a3a3,
                $divider-color: #575757,
                $general-background: #000000,
                $general-background-light: #0e0e0e,
          /* ...other color keys with HEX values... */
        )
);

@mixin themeProperty($theme, $property, $color, $additionalPropertiesPositionIsFront, $additionalProperties) {
  @if $additionalPropertiesPositionIsFront {
    #{$property}: unquote($additionalProperties + ' ' + map-get($theme, $color));
  } @else {
    #{$property}: unquote(map-get($theme, $color) + ' ' + $additionalProperties);
  }
}

@mixin theme($property, $color, $additionalPropertiesPositionIsFront: false, $additionalProperties: '') {
  $light: map-get($themes, $light-theme);
  $dark: map-get($themes, $dark-theme);

  @include themeProperty($light, $property, $color, $additionalPropertiesPositionIsFront, $additionalProperties);

  @media (prefers-color-scheme: dark) {
    @include themeProperty($dark, $property, $color, $additionalPropertiesPositionIsFront, $additionalProperties);
  }
}